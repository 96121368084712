import React from 'react';

import {BugReport} from "../../components/BugReport";
import {EmbedLayout} from "../../containers/EmbedLayout";
import {TwoToneText} from "../../components/TwoToneText";
import {PageState} from "../../containers/PageState";

export type EmbedBugReportProps = {} & PageState;
export default function EmbedBugReport({...props}: EmbedBugReportProps) {
    return <EmbedLayout {...props} meta={{title: 'Bug Report'}}>
        <h1><TwoToneText w1={'Bug'} w2={'Report'} reverse/></h1>
        <BugReport/>
    </EmbedLayout>;
}
